// @flow

import React from "react";
import {
    InternalCommunications,
    Layout,
    AssignmentLayout,
    withPrivateRoute,
} from "@containers";

/**
 * Nota's
 */
const InterneCommunicatie = (props: *) => (
    <Layout {...props}>
        <AssignmentLayout title="Interne Communicatie">
            <InternalCommunications title="Interne Communicatie"></InternalCommunications>
        </AssignmentLayout>
    </Layout>
);

export default withPrivateRoute(
    InterneCommunicatie,
    "/opdrachten/:inspectionId/interne-communicatie",
);
